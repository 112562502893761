<!--公共模块--表格组件-->
<template>
  <a-table :columns="columns" :data-source="conditions" :scroll="{ x: 1300 }" :row-key="record => record.id">    
    <span slot="valid" slot-scope="record">
      <a-radio-group v-model="record.isRun" @change="onChange">
        <a-radio value="1">
        {{ $t('controllIntermittent.a12')}}
          <!-- 有效 -->
        </a-radio>
        <a-radio value="0">
         {{$t('controllIntermittent.a13')}} 
         <!-- 无效 -->
        </a-radio>            
      </a-radio-group>
    </span>    
    <span slot="ontime" slot-scope="record">      
      <a-input :addon-after="$t('controllIntermittent.a14')" v-model="record.ontime" style="width: 100px"/>
    </span>  
    <span slot="offtime" slot-scope="record">      
      <a-input :addon-after="$t('controllIntermittent.a14')" v-model="record.offtime" style="width: 100px"/>
    </span> 
    <span slot="measure" slot-scope="record">      
      <span>{{ record.measurePoint }}</span>
      <a-divider type="vertical" />
      <span>{{ record.dvName }}</span>
      <a-divider type="vertical" />
      <span> {{ record.signalName }}</span>
    </span>
    <span slot="season" slot-scope="record">
      <a-select v-model="record.nowSeason" style="width: 160px" @change="handleChange">
        <a-select-option v-for="item in seasonList" :key="item.key" :value="item.key">
            {{ item.title }}
          </a-select-option>
      </a-select>
    </span>
    <span slot="summerTemperature" slot-scope="record">      
      <a-input :addon-before="$t('controllIntermittent.a15')" addon-after="℃" v-model="record.summerStartTemperature"/>
      <a-input :addon-before="$t('controllIntermittent.a16')" addon-after="℃" v-model="record.summerStopTemperature"/>
    </span>    
    <span slot="winterTemperature" slot-scope="record">      
      <a-input :addon-before="$t('controllIntermittent.a15')" addon-after="℃" v-model="record.winterStartTemperature"/>
      <a-input :addon-before="$t('controllIntermittent.a16')" addon-after="℃" v-model="record.winterStopTemperature"/>
    </span>    
  </a-table>
</template>
<script>



export default {  
  props: {
    condition: {
      type: Object,
    },
    seasonList: {
      type: Array,
    },    
  },
  data() {
    return {
      columns : [
        {
          title: this.$t('controllIntermittent.a11'),//'间歇运行控制',
          key: 'isRun',
          scopedSlots: { customRender: 'valid' },
        },
        {
          title: this.$t('controllIntermittent.a17'),//'运行时间',
          key: 'ontime',
          scopedSlots: { customRender: 'ontime' },
        },
        {
          title: this.$t('controllIntermittent.a18'),//'停止时间',
          key: 'offtime',
          scopedSlots: { customRender: 'offtime' },
        },
        {
          title: this.$t('controllIntermittent.a19'),//'测得点',
          key: 'measure',
          scopedSlots: { customRender: 'measure' },
        },
        {
          title: this.$t('controllIntermittent.a20'),//'季节分组',
          key: 'nowSeason',
          scopedSlots: { customRender: 'season' },
        },
        {
          title: this.$t('controllIntermittent.a21'),//'夏季目标温度',
          key: 'summerTemperature',
          scopedSlots: { customRender: 'summerTemperature' },
        },
        {
          title: this.$t('controllIntermittent.a22'),//'冬季目标温度',
          key: 'winterTemperature',
          scopedSlots: { customRender: 'winterTemperature' },
        },
      ],
      radioValue:1,
      conditions:[this.condition,],
    };
  },
  watch: {
    condition (val, oldVal) {
      if (val !== oldVal) {
        this.conditions.length=0;
        val.key = 1;
        this.conditions.push(val);
      }
      console.log("condition:",this.conditions);
    },   
  },
  methods: {
    onChange(e) {
      console.log('radio checked', e.target.value);
    },
    handleChange(e){
      console.log('select changed', e);
    }
  },
};
</script>
